import classNames from 'classnames'
import React from 'react'
import {Members} from '../../../../members'
import s from './members.scss'

export const MobileCardMembers = ({event, onClick}: {event: ExtendedEvent; onClick?: () => void}) => (
  <div className={classNames(s.members)} data-hook="ev-list-item-members" onClick={onClick}>
    <Members event={event} listLayout />
  </div>
)
