import classNames from 'classnames'
import React, {useEffect} from 'react'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {useWidgetState} from '../../../hooks/state-provider'
import {useEventsList} from '../../../hooks/use-events-list'
import {getComponentConfig, isListLayout} from '../../../selectors/settings'
import {focusFirstNewEvent} from '../../../utils/a11y'
import {CardContainer} from '../../event-container/card-container'
import {ListItemContainer} from '../../event-container/list-item-container'
import {LoadMoreButton} from '../../load-more-button'
import {MainListTitle} from '../../main-list-title'
import {CardsMobileItem} from './cards-mobile-item'
import s from './cards-mobile.scss'

export const CardsMobile = () => {
  const {events, hasMore, moreLoading, pageLimit} = useEventsList()
  const mobile = useWidgetState(isMobile)
  const componentConfig = useWidgetState(getComponentConfig)
  const isDesktopListLayout = isListLayout(componentConfig)

  const className = classNames(s.container, isDesktopListLayout ? s.listContainer : s.cardContainer, {
    [s.noBottomPadding]: isDesktopListLayout,
    [s.narrow]: !mobile,
  })

  const Container = isDesktopListLayout ? ListItemContainer : CardContainer

  const eventsCount = events.length

  useEffect(() => {
    focusFirstNewEvent({eventsCount, pageLimit, hasMore, compId: componentConfig.id})
  }, [eventsCount])

  return (
    <div className={className} data-hook="ev-list-layout">
      <MainListTitle />
      <ul>
        {events.map((event: ExtendedEvent, index: number) => (
          <Container
            key={event.id}
            event={event}
            hideDivider={events.length - 1 === index && !hasMore}
            lastItem={events.length - 1 === index}
            mobile
          >
            {hovered => <CardsMobileItem event={event} hovered={hovered} />}
          </Container>
        ))}
      </ul>
      <div className={s.loadMoreWrapper}>
        <LoadMoreButton mobile hasMore={hasMore} moreLoading={moreLoading} />
      </div>
    </div>
  )
}
